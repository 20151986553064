import {
  IconBriefCase,
  IconClipboardText,
  IconDocumentText,
  IconFavoriteSolid,
  IconHome,
  IconIntroVideo,
  IconListOfApply,
  IconMenuAvatar,
  IconMenuCorporate,
  IconMenuIntro,
  IconMenuJob,
  IconMenuServiceCenter,
  IconMenuSignin,
  IconMenuTalent,
  IconOffer,
  IconPeople,
  IconPortfolio,
  IconResume,
  IconTalentVideo,
  IconVideoInterviewApplicant,
} from "@/app/_components/icons";
import {
  UserInformationValidityPeriod,
  UserType,
} from "@/services/constants/global";
import {
  ADS_SERVICE,
  CORPORATE_DETAIL_PATH,
  CORPORATE_INFORMATION_PATH,
  CORPORATE_MY_PAGE,
  FIND_ID_FORGOT_PW_PAGE,
  JOB_INFORMATION_PATH,
  PERSONAL_DETAIL_PATH,
  PERSONAL_MY_PAGE,
  SERVICE_CENTER,
  SERVICE_CENTER_PATHS,
  SERVICE_INTRODUCTION_PATH,
  SIGNIN_PAGE,
  SIGNUP_PAGE,
  TALENT_INFORMATION_PATH,
  VIDEO_PRODUCT_PROCESS_PATH,
} from "@/services/constants/routerPath";
import { useUserInfo } from "@/services/hooks/useUserInfo";
import { get } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useMultiLanguageConstant() {
  const { t } = useTranslation();
  const userData = useUserInfo();

  const CorporateMenuList = [
    {
      label: t("Corporate Information"),
      url: CORPORATE_MY_PAGE.INFORMATION,
      icon: <IconHome size={24} />,
    },
    {
      label: t("Member Management"),
      url: CORPORATE_MY_PAGE.MEMBER_MANAGEMENT,
      icon: <IconPeople size={24} />,
    },
    {
      label: t("Job Information Registration"),
      url: CORPORATE_MY_PAGE.JOB_REGISTRATION,
      icon: <IconDocumentText size={24} />,
      title: t("Register for job recruitment"),
    },
    {
      label: t("List of Job Information"),
      url: CORPORATE_MY_PAGE.LIST_OF_JOB_RECRUITMENT,
      icon: <IconBriefCase size={24} />,
    },
    {
      label: t("Job recruitment review"),
      url: CORPORATE_MY_PAGE.JOB_RECRUITMENT_REVIEW,
      hide: true,
    },
    {
      label: t("Edit job recruitment"),
      url: CORPORATE_MY_PAGE.JOB_RECRUITMENT_EDIT,
      hide: true,
    },
    {
      label: t("Applicant List"),
      url: CORPORATE_MY_PAGE.LIST_OF_APPLICANT,
      icon: <IconClipboardText size={24} />,
    },
    {
      label: t("List of Talents"),
      url: CORPORATE_MY_PAGE.LIST_OF_TALENT,
      icon: <IconFavoriteSolid size={24} />,
    },
    {
      label: t("Applicant Details"),
      url: CORPORATE_MY_PAGE.APPLICANT_DETAILS_PATH,
      hide: true,
    },
    {
      label: t("Candidate review"),
      url: CORPORATE_MY_PAGE.CANDIDATE_REVIEW,
      icon: <IconListOfApply size={24} />,
    },
    {
      label: t("New candidate review"),
      url: CORPORATE_MY_PAGE.NEW_CANDIDATE_REVIEW,
      hide: true,
    },
    {
      label: t("List of Video Interview (Applicant)"),
      url: CORPORATE_MY_PAGE.VIDEO_INTERVIEW_APPLICANT,
      icon: <IconVideoInterviewApplicant size={24} />,
    },
    {
      label: t("List of Video Interview (Talent)"),
      url: CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
      icon: <IconTalentVideo size={24} />,
      children: [
        {
          label: t("Personal of Interest"),
          url: CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT.PERSONAL_OF_INTEREST,
          title: t("List of Video Interview (Talent - Personal of Interest)"),
        },
        {
          label: t("Personal of Interest for Company"),
          title: t("Video Interview List (Interested candidates)"),
          url: CORPORATE_MY_PAGE.VIDEO_INTERVIEW_TALENT
            .PERSONAL_OF_INTEREST_FOR_COMPANY,
        },
      ],
    },
    {
      label: t("Payment History"),
      url: CORPORATE_MY_PAGE.PAYMENT_HISTORY,
      icon: <IconClipboardText size={24} />,
    },
  ];

  const PersonalMenuList = [
    {
      label: t("Personal Information"),
      url: PERSONAL_MY_PAGE.INFORMATION,
      icon: <IconHome size={24} />,
    },
    {
      label: t("Resume"),
      url: PERSONAL_MY_PAGE.RESUME,
      icon: <IconResume size={24} />,
    },
    {
      label: t("New resume"),
      url: PERSONAL_MY_PAGE.NEW_RESUME,
      hide: true,
    },
    {
      label: t("Edit resume"),
      url: PERSONAL_MY_PAGE.EDIT_RESUME,
      hide: true,
    },
    {
      label: t("My introduction video"),
      url: PERSONAL_MY_PAGE.INTRODUCTION_VIDEO,
      icon: <IconIntroVideo size={24} />,
    },
    {
      label: t("Interested corporate"),
      url: PERSONAL_MY_PAGE.INTERESTED_CORPORATE,
      icon: <IconBriefCase size={24} />,
    },
    {
      label: t("Interested recruitment"),
      url: PERSONAL_MY_PAGE.INTERESTED_RECRUITMENT,
      icon: <IconClipboardText size={24} />,
    },
    {
      label: t("List of offer"),
      url: PERSONAL_MY_PAGE.LIST_OF_OFFER,
      icon: <IconOffer size={24} />,
    },
    {
      label: t("My introduction video"),
      url: PERSONAL_MY_PAGE.NEW_INTRODUCTION_VIDEO,
      hide: true,
    },
    {
      label: t("My introduction video"),
      url: PERSONAL_MY_PAGE.EDIT_INTRODUCTION_VIDEO,
      hide: true,
    },
    {
      label: t("List of apply"),
      url: PERSONAL_MY_PAGE.APPLIED_JOB_LIST,
      icon: <IconListOfApply size={24} />,
    },
    {
      label: t("List of apply"),
      url: PERSONAL_MY_PAGE.INTERVIEW_JOB_DETAIL,
      hide: true,
    },
  ];

  const RoleMenuList = {
    [UserType.PERSONAL]: PersonalMenuList,
    [UserType.CORPORATE]: CorporateMenuList,
  };

  const UserInformationValidityPeriodSelectList = [
    {
      label: t("01 year"),
      value: UserInformationValidityPeriod.ONE_YEAR,
    },
    {
      label: t("05 years"),
      value: UserInformationValidityPeriod.FIVE_YEAR,
    },
    {
      label: t("Until membership withdrawal"),
      value: UserInformationValidityPeriod.UNTIL_MEMBERSHIP_WITHDRAW,
    },
  ];

  const Gender = [
    {
      label: t("Male"),
      value: "MALE",
    },
    {
      label: t("Female"),
      value: "FEMALE",
    },
    {
      label: t("Irrelevant"),
      value: "IRRELEVANT",
    },
  ];

  const Education = [
    {
      label: t("Enrolled"),
      value: "ENROLLED",
    },
    {
      label: t("Graduated"),
      value: "GRADUATED",
    },
    {
      label: t("Dropout"),
      value: "DROPOUT",
    },
  ];

  const NavigationMenu = useMemo(
    () => [
      {
        label: t("Sign Up"),
        url: SIGNUP_PAGE,
        isUseForMobileOnly: true,
        icon: <IconMenuAvatar />,
      },
      {
        label: t("Sign In"),
        url: SIGNIN_PAGE,
        isUseForMobileOnly: true,
        icon: <IconMenuSignin />,
      },
      {
        label: t("Find ID/Password"),
        url: FIND_ID_FORGOT_PW_PAGE,
        isUseForMobileOnly: true,
      },
      {
        label: userData?.fullName,
        url: SIGNUP_PAGE,
        isUseForMobileOnly: true,
        icon: <IconMenuAvatar />,
        sub:
          RoleMenuList[get(userData, "type")]?.filter(
            (item) => !get(item, "hide")
          ) || [],
      },
      {
        label: t("About Our Service"),
        url: SERVICE_INTRODUCTION_PATH,
        isUrlDisabled: true,
        icon: <IconMenuIntro />,
        sub: [
          {
            label: t("Service Introduction"),
            url: SERVICE_INTRODUCTION_PATH,
          },
          {
            label: t("Video Production Process"),
            url: VIDEO_PRODUCT_PROCESS_PATH,
          },
          {
            label: t("Job Advertising Guide"),
            url: `${ADS_SERVICE}?target=JOB`,
            hideToRoles: [UserType.PERSONAL],
          },
          {
            label: t("Corporate Advertising Guide"),
            url: `${ADS_SERVICE}?target=BANNER`,
            hideToRoles: [UserType.PERSONAL],
          },
        ],
      },
      {
        label: t("Corporate Information"),
        url: CORPORATE_INFORMATION_PATH,
        icon: <IconMenuCorporate />,
        hideToRoles: [UserType.CORPORATE],
        sub: [
          {
            label: t("By Region"),
            url: "/corporate-information/region",
          },
          {
            label: t("By Brand"),
            url: "/corporate-information/brand",
          },
          {
            label: t("By Type Of Corporate"),
            url: "/corporate-information/corporate",
          },
          {
            label: t("HOT 10 Corporates"),
            url: "/hot-ten/corporate",
          },
          {
            label: t("Favorite Corporates"),
            url: PERSONAL_MY_PAGE.INTERESTED_CORPORATE,
          },
        ],
      },
      {
        label: t("Corporate Information"),
        url: "/hot-ten/corporate",
        isUseForMobileOnly: true,
      },
      {
        label: t("Job Posting"),
        url: CORPORATE_MY_PAGE.LIST_OF_JOB_RECRUITMENT,
        onlyShowToRoles: [UserType.CORPORATE],
        icon: <IconMenuJob />,
        sub: [
          {
            label: t("Post Job Listings"),
            url: CORPORATE_MY_PAGE.JOB_REGISTRATION,
          },
          {
            label: t("Manage Job Listings"),
            url: CORPORATE_MY_PAGE.LIST_OF_JOB_RECRUITMENT,
          },
          {
            label: t("Job Advertising Application"),
          },
          {
            label: t("Apply for Package Job Advertising"),
          },
          {
            label: t("Job Advertising History"),
            url: CORPORATE_MY_PAGE.PAYMENT_HISTORY,
          },
        ],
      },
      {
        label: t("Job Information"),
        hideToRoles: [UserType.CORPORATE],
        url: JOB_INFORMATION_PATH,
        icon: <IconMenuJob />,
        sub: [
          {
            label: t("By Region"),
            url: "/job-information/region",
          },
          {
            label: t("By Position"),
            url: "/job-information/duty",
          },
          {
            label: t("By Work Type"),
            url: "/job-information/workType",
          },
          { label: t("Near Subway"), url: "/job-information/subway" },
          {
            label: t("HOT 10 Jobs"),
            url: "/hot-ten/job",
          },
          {
            label: t("Recruitment Of Interest"),
            url: PERSONAL_MY_PAGE.INTERESTED_RECRUITMENT,
          },
        ],
      },
      // {
      //   label: t("Headhunting"),
      //   url: "#",
      //   icon: <IconMenuCorporate />,
      //   sub: [
      //     {
      //       label: t("By Region"),
      //       url: "#",
      //     },
      //     {
      //       label: t("By Position"),
      //       url: "#",
      //     },
      //     {
      //       label: t("By Work Type"),
      //       url: "#",
      //     },
      //     {
      //       label: t("By Station Area"),
      //       url: "#",
      //     },
      //     {
      //       label: t("Recruitment Of Interest"),
      //       url: "#",
      //     },
      //   ],
      // },
      // {
      //   label: t("Community"),
      //   url: "#",
      //   icon: <IconMenuCorporate />,
      //   sub: [
      //     {
      //       label: t("Interview Reviews"),
      //       url: "#",
      //     },
      //     {
      //       label: t("Successful Applicant Reviews"),
      //       url: "#",
      //     },
      //     {
      //       label: t("Request For Job Counseling"),
      //       url: "#",
      //     },
      //     {
      //       label: t("History Of Job Counseling"),
      //       url: "#",
      //     },
      //   ],
      // },
      {
        label: t("Job Information"),
        url: "/hot-ten/job",
        isUseForMobileOnly: true,
      },
      {
        label: t("Applicant Management"),
        onlyShowToRoles: [UserType.CORPORATE],
        icon: <IconPortfolio />,
        url: CORPORATE_MY_PAGE,
        sub: [
          {
            label: t("Applicant List"),
            url: CORPORATE_MY_PAGE.LIST_OF_APPLICANT,
          },
          {
            label: t("Video Interview List"),
            url: CORPORATE_MY_PAGE.VIDEO_INTERVIEW_APPLICANT,
          },
          { label: t("Acceptance Notification") },
        ],
      },
      {
        label: t("Talent Information"),
        url: TALENT_INFORMATION_PATH,
        isUrlDisabled: true,
        onlyShowToRoles: [UserType.CORPORATE],
        icon: <IconMenuTalent />,
        sub: [
          {
            label: t("Talent Search"),
            url: TALENT_INFORMATION_PATH,
          },
          {
            label: t("Favorite Candidates"),
          },
          {
            label: t("Interested Candidates"),
          },
          { label: t("Video Interview List") },
        ],
      },
      {
        label: t("Customer Service"),
        url: SERVICE_CENTER,
        isUrlDisabled: true,
        icon: <IconMenuServiceCenter />,
        sub: [
          {
            label: t("Notices"),
            url: SERVICE_CENTER_PATHS.QNA,
          },
          {
            label: t("Event/ Survey"),
            url: SERVICE_CENTER_PATHS.EVENT_SURVEY,
          },
          {
            label: t("Inquiry/ Counsel"),
            url: SERVICE_CENTER_PATHS.INQUIRY_COUNSEL,
          },
          {
            label: t("FAQ"),
            url: SERVICE_CENTER_PATHS.QNA,
          },
          // {
          //   label: t("Notice Board"),
          //   url: SERVICE_CENTER_PATHS.NOTICE_BOARD,
          // },
          {
            label: t("User Guide"),
            url: "#",
          },
          {
            label: t("Product Guide"),
            url: "#",
          },
          {
            label: t("Find ID/Password"),
            url: SERVICE_CENTER_PATHS.RECOVER_ACCOUNT,
          },
          // {
          //   label: t("Membership Withdrawal"),
          //   url: SERVICE_CENTER_PATHS.MEMBERSHIP_WITHDRAWAL,
          // },
          {
            label: t("Cancel Membership"),
            url: "#",
          },
        ],
      },
      {
        label: t("My Page"),
        url: CORPORATE_DETAIL_PATH,
        isUseForMobileOnly: true,
      },
      {
        label: t("My Page"),
        url: PERSONAL_DETAIL_PATH,
        isUseForMobileOnly: true,
      },
    ],
    [t]
  );

  const MenuFooterList = [
    {
      label: t("Service Introduction"),
      url: SERVICE_INTRODUCTION_PATH,
    },
    {
      label: t("Corporate Information"),
      url: CORPORATE_INFORMATION_PATH,
    },
    {
      label: t("Job Information"),
      url: JOB_INFORMATION_PATH,
    },
    {
      label: t("Head Hunting"),
      url: "",
    },
    {
      label: t("Community"),
      url: "",
    },
  ];

  const ResumeStatus = { OPEN: t("Public"), NOT_OPEN: t("Private") };
  return {
    Education,
    Gender,
    UserInformationValidityPeriodSelectList,
    RoleMenuList,
    MenuFooterList,
    NavigationMenu,
    ResumeStatus,
  };
}
